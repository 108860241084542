@import '../../../scss/templates';

.wallet-card {
  height: 105px;
  width: 199px;
  padding: 16px 23px 16px 27px;
  font-size: 14px;

  &:first-child {
    color: $color-purple;
    @extend %bordered;
    :global(.text-14-regular) {
      color: $color-black;
    }

    & svg path {
      fill: $color-purple;
    }
  }

  &:nth-child(2n) {
    color: white;
    @extend %background-purple;

    & svg path {
      fill: white;
    }
  }
}
