@import '../../../../scss/templates';

@keyframes line-width {
  0% {
    transform: scaleX(0);
  }

  100% {
    transform: scaleX(1);
  }
}

.new-navbar-link {
  min-height: auto !important;
  padding: 0 30px 0 0 !important;

  border-radius: 0 !important;

  position: relative;
  color: white;
  font-size: 16px;

  &:first-child {
    //padding-left: 0 !important;
  }

  + .new-navbar-link {
    //border-left: 1px solid #ffffff;

    @media screen and (max-width: 767px) {
      border-left: none;
    }
  }

  div {
    &:after {
      transition: transform 0.3s ease-in;
    }

    &.new-navbar-item--active:after {
      position: absolute;
      content: '';
      display: block;
      width: calc(100% - 30px);
      bottom: -2px;
      left: 0;
      margin: 0 auto;
      border-bottom: 1px solid #ffffff;
      animation: line-width 0.3s;
      transform-origin: center;

      @media screen and (max-width: 768px) {
        width: 100%;
        left: 0;
      }
    }
  }

  &:focus,
  &:hover {
    color: #ffffff;

    &:after {
      height: 1px;
      bottom: 0;
    }
  }

  .new-nav-link-title {
    font-weight: 400;
    // text-transform: uppercase;
  }

  @media screen and (max-width: 767px) {
    + .new-navbar-link {
      border-left: none;
      margin-top: 0.75rem;
    }
    div {
      margin: 0.125rem 0;
    }
  }
}
