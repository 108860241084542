@import '../../../scss/templates';

.checkbox {
  -moz-appearance: initial;
  float: left;
  margin-top: 0;
  margin-left: 0;
  margin-right: 14px;
  visibility: hidden;
  display: inline;
  border-radius: unset;
  position: relative;
  &:after {
    content: '';
    visibility: visible;
    position: absolute;
    left: 0;
    top: 0;
    width: 15px;
    height: 15px;
    @extend %bordered;
  }
  &:checked:after {
    border: none;
    @extend %background-purple;
  }
}
