@import '../../../scss/templates';

.navbar {
  --tblr-navbar-border-width: 0;

  .desktop {
    display: initial;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .mobile {
    display: none;

    @media screen and (max-width: 768px) {
      display: initial;
    }
  }
}

.navbar__balance {
  &.desktop {
    display: flex;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  &.mobile {
    display: none;

    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
}

.navbar__navigation {
  &.desktop {
    display: initial;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  &.mobile {
    display: none;

    @media screen and (max-width: 768px) {
      display: initial;
    }
  }
}

.new-nav-bar {
  background: $color-gradient;
  z-index: 5;

  * {
    background: transparent;
    box-shadow: none;
  }

  .navbar {
    min-height: auto;
  }

  .navbar-nav {
    min-height: auto;
    height: fit-content;
    flex-direction: row;
  }

  @media screen and (max-width: 768px) {
    padding: 2.5rem 2rem;
    width: 196px;

    .nav-link.new-navbar-link {
      justify-content: flex-start;
      padding: 0.5rem 0 !important;
      margin: 0;

      .new-nav-link-title {
        font-size: 14px;
        text-transform: initial;
        letter-spacing: 0.7px;
      }

      .nav-link-icon {
        width: 15px;
      }
    }
  }
}

:global(.navbar-background) {
  @extend %background-gradient;
}
