.title {
  position: relative;

  button {
    border: none;
    background: transparent;
    position: absolute;
    left: -50px;
    bottom: 3px;
    padding: 0;

    @media screen and (max-width: 767px) {
      left: -30px;
    }

    svg {
      height: 14px;
    }
  }
}

.modal {
  :global(.modal_title) {
    margin: 0 0 32px;
  }

  :global(.text--page-title) {
    margin-top: 0;
  }

  :global(.modal-content) {
    padding: 48px 67px;
  }

  :global(.modal-content.auth) {
    @media screen and (max-width: 576px) {
      padding: 0 1.5rem;
    }
  }

  :global(.modal-dialog) {
    max-width: 550px;

    @media screen and (max-width: 767px) {
      :global(.modal-content) {
        padding-left: 3em !important;
        padding-right: 3em !important;
      }
    }
  }
}
