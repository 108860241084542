@import '../../../../scss/variables';

%icon {
  path {
    fill: #8c8c8c;
  }
}

.delete,
.edit,
.stats {
  @extend %icon;
}

button:hover {
  .delete,
  .edit {
    path {
      fill: $color-purple;
    }
  }
  .stats {
    path {
      fill: $color-pink;
    }
  }
}
