.page-header-buttons {
  align-items: end;
  display: inline-flex;
}

@media (max-width: 1200px) {
  .page-header-buttons {
    width: 100%;
    margin-top: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
