@import 'variables';

/* dot 1)message */

%message {
  display: flex;

  div {
    min-width: 10px;
    max-width: 200px;
    padding: 20px;
    word-wrap: normal;
    overflow-wrap: break-word;
  }

  p {
    word-break: break-word;
    max-width: 300px;
  }
}

.chat-message {
  .me {
    @extend %message;
    justify-content: flex-end;
    p {
      border: 1px solid $color-pink;
      background-color: $color-pink--pale;
      padding-left: 20px;
      padding-right: 5px;

      text-align: right;
    }
  }

  .companion {
    @extend %message;
    justify-content: flex-start;
    p {
      border: 1px solid $color-purple;
      background-color: $color-purple--pale;
      padding-left: 20px;
      padding-right: 5px;
      text-align: left;
    }
  }
}

/* dot 2)containers */
.dashboard-form-container {
  max-width: 950px;
}

%card {
  height: 100%;
  border: 1px solid #515cd1;
  border-radius: 0;
}

.primary {
  @extend %card;
  padding: 41px 72px 50px 40px;
}

.secondary {
  @extend %card;
  padding: 41px 24px 21px 24px;
}

.conditions-title {
  margin-bottom: 37px;
}

.avatar-99px {
  width: 53px;
  height: 53px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.h-line {
  height: 1px !important;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
}

.h-line_2px {
  height: 2px !important;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
}

.radio-130 {
  max-width: 130px;

  @media screen and (max-width: 1280px) {
    max-width: unset;
    width: 100%;
  }
}

.offer_card {
  padding: 32px 32px 65px 32px;

  @media screen and (max-width: 767px) {
    padding: 1rem;
  }
}

.offer_response {
  min-height: 109px;
  padding: 10px 24px 10px 10px;
}

.form__back-arrow{
  left: 0;
  position: absolute;

  @media screen and (max-width: 400px){
    position: relative;
  }
}

.form__title{
  padding-left: 30px;
  padding-right: 30px;

  @media screen and (max-width: 400px){
    padding: 0;
    text-align: left;
  }

}
