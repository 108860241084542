@import './templates';

.text--gradient {
  @extend %text-gradient;
}

.text--disabled {
  @extend %disabled-text;
}

.text--page-title {
  margin-top: 40px;
  margin-bottom: 40px;
  font-family: 'Geometria', sans-serif, Verdana;
  text-transform: uppercase;
  text-align: center;
  @extend %text-25-bold;

  @media screen and (max-width: 767px) {
    font-size: 20px;
  }
}

.text--auth-title {
  @extend %text-25-bold;
  font-family: 'Geometria', sans-serif, Verdana;
  text-transform: uppercase;
}

.text-white {
  color: #ffffff !important;
}

.text-16 {
  font-size: 16px;
}

.text-16-regular {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.background-purple--pale {
  background: $color-purple--pale;
}

.background-purple {
  background: $color-purple;
}

.text-16-bold {
  font-size: 16px;
  font-weight: 700;
}

.text-17-bold {
  font-size: 17px;
  font-weight: bold;
}

.text-30-bold {
  font-size: 30px;
  font-weight: bold;
  line-height: 38px;
}

.text-12-bold {
  font-size: 12px !important;
  font-weight: 700 !important;
}

.text-12-regular {
  font-size: 12px !important;
  font-weight: 400 !important;
}

.text-14-regular {
  font-size: 14px !important;
  font-weight: 400 !important;
}

.text-14-bold {
  font-size: 14px !important;
  font-weight: 700 !important;
}

.text-17-regular {
  font-size: 17px;
  font-weight: 400;
}

.text--purple {
  color: $color-purple;
}

.text--black {
  color: $color-black;
}

.text-gradient {
  background: $color-gradient;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-25 {
  font-size: 25px !important;
  line-height: 31.45px;
}

.text-20 {
  font-size: 20px !important;
  line-height: 25px;
}

.bordered {
  @extend %bordered;
}

.bordered--thick {
  border-width: 2px !important;
}

.f-geometria {
  font-family: 'Geometria', sans-serif;
  font-weight: 700;
  font-size: 16px;
}

.row-wrap-961 {
  @media screen and (max-width: 961px) {
    flex-direction: column;
  }
}

.full-width-961 {
  @media screen and (max-width: 961px) {
    width: 100% !important;
  }
}

.row-wrap-1366 {
  @media screen and (max-width: 1366px) {
    flex-direction: column;
  }
}

.full-width-1366 {
  @media screen and (max-width: 1366px) {
    width: 100% !important;
  }
}

.w-240 {
  width: 240px;

  @media screen and (max-width: 380px) {
    width: auto;
  }
}

.w-280 {
  width: 240px;

  @media screen and (max-width: 380px) {
    width: auto;
  }
}

.background-gradient {
  @extend %background-gradient;
}

.border-gradient {
  border-image: $color-gradient !important;
  border-width: 1px;
  border-style: solid;
}

.mxw-550 {
  max-width: 550px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-40 {
  margin-bottom: 40px;
}

.white-space--break {
  white-space: break-spaces;
}

.truncate-3-lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.mh-40 {
}



.p-32 {
  padding: 32px;
}

.fs-14 {
  font-size: 14px;
  line-height: 17px;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-24 {
  font-size: 24px;
}

.my-50 {
  margin: 50px 0;
}

.blogger__similar {
  &.blogger__similar--offer {
    .border_purple {
      height: 281px;
    }

    .swiper-button-prev,
    .swiper-button-next {
      top: 60%;
    }

    .swiper-button-prev {
      left: -35px;
    }

    .swiper-button-next {
      right: -35px;
    }
  }
}

.form-check {
  padding-left: 0 !important;
}

@media screen and (max-width: 576px) {
  .border-sm-none {
    border: unset !important;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}


.desktop {
  display: initial;
  @media screen and (max-width: 767px) {
    display: none !important;
  }
}
.mobile {
  display: none;
  @media screen and (max-width: 767px) {
    display: initial;
  }
}

.offer__thumbnail{
  height: 100%;
  width: 100%;
}
