@import '../../../scss/templates';

%blogger-statistics {
  color: white;
  border-radius: 0;

  :global(.text-16-bold) {
    font-size: 1.5rem;
    @media screen and (max-width: 576px) {
      font-size: 1rem !important;
    }
  }
}

.primary {
  @extend %blogger-statistics;
  @extend %background-purple;
}

.secondary {
  @extend %blogger-statistics;
  @extend %background-pink;
}
