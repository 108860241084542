@import '../../../scss/templates';

.dashboard {
  .dashboard-btns {
    &__side-btns {
      .btn:first-child {
        margin-right: 1.5rem;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    .dashboard-btns {
      flex-direction: column;
      align-items: center;
      width: 100%;

      &__side-btns {
        .btn:first-child {
          margin-right: 0;
        }

        width: 100%;
        flex-direction: column;
        align-items: center;

        .btn {
          width: 40%;

          + .btn {
            margin-top: 0.75rem;
          }
        }
      }
    }
  }

  @media screen and (max-width: 960px) {
    .dashboard-btns {
      flex-direction: column;
      align-items: center;
      width: 100%;

      &__side-btns {
        width: 100%;
        flex-basis: initial;
        flex-direction: column;
        align-items: center;

        .btn {
          width: 60%;

          + .btn {
            margin-top: 0.75rem;
          }
        }
      }
    }
  }

  @media screen and (max-width: 640px) {
    .dashboard-btns {
      &__side-btns .btn {
        width: 80%;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .dashboard-btns {
      .btn {
        font-size: 14px;

        img {
          margin-left: 1rem !important;
        }
      }

      &__side-btns .btn {
        width: 100%;
      }
    }
  }
}

.dashboard-card {
  display: block !important;
  border: 1px solid $color-purple;
  border-radius: 0;
  padding: 30px 25px;
  height: 110px;

  &.pink {
    border-color: $color-pink;

    .middle-block {
      border-color: $color-pink;
    }
  }

  &.light {
    background-color: #ffffff;
  }

  &.purple {
    background-color: rgba(96, 109, 255, 0.15);
  }

  .card-body {
    height: 100%;
  }

  &_advertiser {
    padding: 15px 20px;
  }

  &.dashboard-card_blogger {
    padding: 10px;
    .first-block {
      width: 50%;
    }
    .middle-block {
      width: 50%;
      border-right: unset;
    }
  }

  .first-block {
    width: 35%;

    position: relative;

    .social-icon {
      position: absolute;
      right: 1%;
      top: -10px;
    }

    .avatar-md {
      border-radius: 0;
      min-width: 90px;
      min-height: 90px;
    }
  }

  .middle-block {
    width: 42%;
    border-left: 1px solid $color-purple;
    border-right: 1px solid $color-purple;
    padding: 10px;

    align-self: start;

    p {
      margin: 0;
      max-height: 100%;
      width: 100%;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-weight: 700;
    }
  }

  .last-block {
    width: 22%;
  }

  .blogger-username {
    color: $color-purple;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 700;
  }

  @media screen and (max-width: 1200px) {
    &.dashboard-card_blogger {
      .avatar-md {
        min-width: 5rem;
        min-height: 5rem;
      }
    }
  }

  @media screen and (max-width: 575px) {
    height: 100%;
    max-height: 350px;

    &.dashboard-card_blogger {
      .first-block {
        margin-bottom: 0.5rem;
      }
      .middle-block {
        border-bottom: none;
        padding-top: 0.5rem;
        padding-bottom: 0;
      }
    }

    .first-block {
      margin-bottom: 1rem;

      > * + * {
        margin-top: 0.25rem !important;
      }
    }

    .middle-block {
      padding: 1rem 0;
      border: none;
      border-top: 1px solid $color-purple;
      border-bottom: 1px solid $color-purple;
    }

    .last-block {
      margin-top: 1rem;
    }

    .row.row-deck {
      flex-direction: column;
      height: 100%;
    }

    .first-block,
    .middle-block,
    .last-block {
      width: 100% !important;
    }
  }
}
