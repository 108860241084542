.blogger-card {
  border: 1px solid var(--main-purple);
  border-radius: 0;
  height: 100%;

  .platform-icon{
    position: absolute;
    right: 0.5em;
    top: 0.5em;
  }

  .row {
    flex-wrap: nowrap;
  }

  &.light {
    background-color: #ffffff;
  }

  &.purple {
    background-color: rgba(96, 109, 255, 0.15);
  }

  h4 {
    font-size: 17px;
    line-height: 1.2em;
    letter-spacing: 0.01em;
    text-transform: uppercase;

    color: #515cd1;
  }

  .title {
    height: 100%;
    flex-basis: 37%;
    overflow: hidden;
    border-right: 1px solid var(--main-purple);

    .column {
      white-space: nowrap;
      min-width: 0;
      text-overflow: ellipsis;
      width: 100%;

      .fullname {
        overflow-wrap: anywhere;

        color: #111111;
      }
    }

    .avatar {
      border-radius: 0;
    }

    .avatar-md {
      min-width: 89px;
      min-height: 89px;
    }

    .avatar + .column {
      margin-left: 1rem;
    }
  }

  .stats {
    flex-basis: 37%;
    align-items: center;
    flex-wrap: no-wrap;
    justify-content: space-evenly;

    > .column {
      margin: 0 0.5rem;
    }

    .purple,
    .pink {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;

      letter-spacing: 0.01em;
    }

    svg {
      align-self: center;
    }
  }

  .price {
    height: 100%;
    flex-basis: 26%;
    margin: 0rem;
    width: 180px;
    padding-left: 1rem;

    div {
      text-align: center;
      color: #111111;
    }

    button {
      width: 100%;
      line-height: 125%;
    }
  }

  @media screen and (max-width: 1400px) {
    .stats {
      justify-content: space-evenly;
      align-items: center;
      min-width: 0;
      flex-wrap: wrap;
    }
  }

  @media screen and (max-width: 1200px) {
    font-size: 12px;
    height: 109px;

    .title .avatar-md {
      min-width: 5rem;
      min-height: 5rem;
    }
  }

  @media screen and (max-width: 900px) {
    height: auto !important;
    flex-direction: column;
    align-items: flex-start;

    .row {
      width: 100%;
      flex-direction: column;
    }

    .title {
      flex-basis: auto;
      width: 100%;
      border-right: 1px solid var(--main-purple);
      border-right: none;
      justify-content: flex-start;
      border-bottom: 1px solid var(--main-purple);

      .column {
        width: auto;
      }
    }

    .stats {

      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap !important;
      row-gap: 0.7em;
      width: 100%;

      padding: 1rem 0 0;

      > .column {
        margin: 0 0.25rem;
      }
    }

    .price {
      margin-left: 0;
      padding: 0.5rem 0 0;
      width: 100%;
      flex-direction: column-reverse;
      justify-content: space-between;
      border-top: 1px solid var(--main-purple);
      border-left: none;
      border-right: none;
      div {
        margin-top: 0;
      }

      button {
        margin-top: 0.5rem;
        width: 60%;
      }
    }
  }

  @media screen and (max-width: 768px) {
  }

  @media screen and (max-width: 575px) {
    min-height: 393px;

    .price {
      button {
        width: 70%;
      }
    }
    .title {
      flex-direction: column;
      row-gap: 1rem;
      align-items: center;
    }

    .btn {
      font-size: 12px !important;
    }
  }

  @media screen and (max-width: 340px) {
    min-height: 450px;
  }
}
