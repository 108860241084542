@import '../../../scss/variables';

.swiper {
  position: initial;
  width: 90%;
}

.swiper-container {
  width: 100%;
}

.swiper-slide {
  text-align: center;
  width: auto;
}

.swiper-button-prev,
.swiper-button-next {
  width: 10px;
  height: 10px;
  border-top: 2px solid $color-purple;
  border-right: 2px solid $color-purple;
  position: absolute;
  z-index: 10;
  top: 70%;
  color: $color-purple;
  cursor: pointer;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  content: ' ';
}

.swiper-button-prev {
  transform: rotate(-135deg);
  left: 10px;
  right: auto;
}

.swiper-button-next {
  transform: rotate(45deg);
  right: 10px;
  left: auto;
}
