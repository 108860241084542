$color-notification--error: rgba(255, 113, 113, 0.15);
$color-notification--warning: rgba(255, 214, 74, 0.15);
$color-notification--info: rgba(96, 109, 255, 0.15);
$color-notification--success: rgba(90, 223, 28, 0.15);

.notification {
  padding: 12px 32px 12px 20px;

  svg {
    min-height: 36px;
    min-width: 36px;
  }

  :global(.btn) {
    font-weight: bold;
  }
}

.error {
  background-color: $color-notification--error;

  :global(.notification-title) {
    color: rgba(203, 59, 59, 1);
    :global(.btn) {
      background-color: rgba(203, 59, 59, 1);
      color: rgba(203, 59, 59, 1);
    }
  }
}
.warning {
  background-color: $color-notification--warning;
  :global(.notification-title) {
    color: rgba(232, 161, 54, 1);
  }
  :global(.btn) {
    border-color: rgba(232, 161, 54, 1);
    color: rgba(232, 161, 54, 1);
  }
}
.info,
.placement {
  background-color: $color-notification--info;
  :global(.notification-title) {
    color: rgba(81, 92, 209, 1);
  }
  :global(.btn) {
    border-color: rgba(81, 92, 209, 1);
    color: rgba(81, 92, 209, 1);
  }
}
.success {
  background-color: $color-notification--success;
  :global(.notification-title) {
    color: rgba(34, 139, 17, 1);
  }
  :global(.btn) {
    border-color: rgba(34, 139, 17, 1);
    color: rgba(34, 139, 17, 1);
  }
}
