@import '../../../scss/variables';
@import '../../../scss/templates';

.styled {
  & > * {
    border: $color-purple 1px solid !important;
    border-radius: 0 !important;
    height: inherit;
    width: inherit;
    min-height: inherit !important;
    max-width: inherit;

    :global(.dz-image-preview) {
      top: 0;
      height: 100% !important;
      min-height: 100% !important;
      max-width: inherit;
      margin: 0 !important;

      :global(.dz-image) {
        border-radius: 0 !important;
        height: 100% !important;
        width: 100% !important;
        min-height: 100% !important;
        max-width: inherit;
      }

      :global(.dz-remove) {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 50;
      }
    }
  }
}

.borderless {
  border: none;
}

.dropzone-47 {
  height: unset;
  min-height: 47px;

  :global(div.dropzone) {
    padding: 15px;
  }

  :global(div.filepicker) {
    min-height: 47px;
    @extend %bordered;
  }

  :global(div.dz-message) {
    margin: 0;
    font-size: 14px;
    line-height: 16px;
  }

  :global(div .dz-preview .dz-image) {
    border-radius: 0;
  }
}
