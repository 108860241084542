@import '../../scss/templates';

%card {
  height: 100%;
  @extend %bordered;
}

.primary {
  @extend %card;
  padding: 41px 72px 50px 40px;
}

.secondary {
  @extend %card;
  padding: 41px 24px 21px 24px;
}

.conditions {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
