%input__alert {
  padding-top: 12px;
  text-align: center;
}

.input__alert--error {
  @extend %input__alert;
  color: red;
}

.input__alert--success {
  @extend %input__alert;
  color: green;
}

.input__alert--pending {
  @extend %input__alert;
  color: orange;
}
