.nav-bar-separator {
  margin-top: 15px;
  margin-right: 25px;
  margin-left: 25px;
  height: 25px;
  width: 1px;
  background-color: white !important;

  @media (max-width: 768px) {
    display: none;
  }
}
