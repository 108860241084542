@import '../../../scss/templates';

.button-switch {
  input[type='checkbox'] {
    display: none;
  }

  label {
    box-sizing: content-box !important;
    cursor: pointer;
    text-indent: -9999px;
    width: 50px;
    height: 20px;
    background: #ffffff;
    border: 1px solid $color-pink;
    display: block;
    border-radius: 100px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      background: $color-gradient;
      top: 4.5px;
      left: 7px;
      width: 11px;
      height: 11px;
      border-radius: 50%;
      transition: 0.3s;
    }

    &:active:after {
      width: 13px;
    }
  }

  input:checked + label {
    border: none;

    &:after {
      left: calc(100% - 8px);
      transform: translateX(-100%);
      background: #ffffff;
    }

    &:before {
      position: absolute;
      content: '';
      left: 0;
      width: 50px;
      height: 20px;
      background: $color-gradient;

      border-radius: 100px;
    }
  }
}
