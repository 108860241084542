@import '../../../scss/templates';

%input {
  @extend %bordered;
  @extend %text-14-regular;
  padding: 15px 30px;
  border-radius: 0;

  font-family: 'Lato', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

  &:focus {
    border-width: 2px;
  }
  &:before {
    display: none;
  }
}

.input {
  @extend %input;
  height: 47px !important;
  cursor: text;
}

.textarea {
  @extend %input;
  box-sizing: border-box;
  min-height: 100%;
}

.double-range-input {
  :global(.input-group):first-child {
    :global(.input-group-text) {
      @extend %bordered;
      border-right: none;
      background-color: transparent;
    }
  }
  :global(.input-group):nth-child(2) {
    :global(.input-group-text) {
      @extend %bordered;
      background-color: transparent;
      border-left: none;
      border-right: none;
    }
  }
}

.phone-input.phone-input {
  input {
    width: 100%;
    @extend %input;
    min-height: 47px;
    padding-left: 50px;
  }
  div {
    background-color: white;
    border-radius: unset;
    border-color: $color-purple;
  }
}

.input-group {
  height: 47px !important;
  display: flex;
  width: 100%;

  & > * {
    height: 100%;
  }

  & > *:first-child {
    @extend %bordered;
    border-left: 1px $color-purple solid !important;
  }

  & > *:last-child {
    @extend %bordered;
  }

  :global(.input-group-text) {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    height: 100%;
    border-left: #515cd1 1px solid;
  }

  :global(.input_price-unit) {
    width: 79px;
  }

  :global(.input-group_label) {
    border-right: #515cd1 1px solid;
  }

  :global(.input-label) {
    display: flex;
    height: 100%;
    align-items: center;
    padding: 15px;
  }

  input {
    padding: 15px 30px;
    box-shadow: none !important;
  }
}

.date-input {
  :global(.input-placeholder) {
    display: none;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    cursor: text;
    pointer-events: none;

    align-items: center;
    padding-left: 30px;
  }

  :global(.input-blurred) {
    :global(.input-placeholder) {
      display: flex;
    }

    .input[type='date'] {
      color: transparent;
    }
  }

  overflow: hidden;
  position: relative;
  display: flex;
  input {
    @extend %input;
    z-index: -30;
    height: 48px;

    &:global(.form-control) {
      box-shadow: none !important;
    }

    &:empty {
      @extend %disabled-text;
    }
  }
  input[type='date']::-webkit-inner-spin-button,
  input[type='date']::-webkit-calendar-picker-indicator {
    opacity: 0;
    -webkit-appearance: none;
  }

  .calendar-icon {
    z-index: 2;
    position: absolute;
    right: 30px;
    top: 13px;
    height: 50%;
    cursor: pointer;
    pointer-events: none;
    background-color: white;
    overflow: visible;
  }

  &:after {
    z-index: 1;
    content: '';
    position: absolute;
    right: 78px;
    height: 100%;
    background-color: $color-purple;
    width: 0.5px;
  }

  @media screen and (max-width: 466px) {
    flex-wrap: wrap;

    .calendar-icon {
      height: auto;
      top: unset;
      bottom: 15px;
    }
  }
}

.password-wrapper,
.filters-wrapper {
  max-width: 100%;
  justify-content: space-between;
  @extend %bordered;
  .input {
    box-shadow: none;
    border: none !important;
  }

  .show-password,
  .filters {
    border: none !important;
    margin: 0;
    background-color: transparent;
    align-items: center;
    display: flex;
    padding-right: 28px;
  }

  .filters {
    @media screen and (min-width: 767px) {
      display: none;
      .input {
        width: 100%;
      }
    }
  }
}

.price-input {
  position: relative;
  display: flex;

  label {
    word-break: keep-all;
  }
  span {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    vertical-align: middle;
    right: 0;
    height: 47px;
    font-family: 'Lato', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
      'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    border-left: $color-purple 1px solid;
    @extend %disabled-text;
    padding: 10px 35px 10px 35px;
  }

  input {
  }
}
