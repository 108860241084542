@import '../../../scss/templates';

.btn {
  font-size: 14px;
  line-height: 16.8px;
  font-weight: 700;
  border-radius: 100px !important;
  padding-top: 16px;
  padding-bottom: 17px;
  text-transform: uppercase;
  transition: transform ease-in 0.1s;

  &:hover:not([disabled]) {
    transform: scale(1.03);
  }

  &:active {
    transform: scale(0.97) !important;
    background-color: inherit !important;
    color: inherit !important;
  }

  &:disabled {
    @extend %disabled;
  }

  &.btn_primary {
    @extend %background-gradient;
    color: white;
    border: none;
  }

  &.btn_secondary {
    border-color: $color-pink !important;
    background-color: transparent;
    &.border_purple {
      border-color: $color-purple !important;
    }
  }

  &.btn_secondary--purple {
    border-color: $color-purple !important;
    background-color: transparent;
  }

  &.btn--small {
    font-size: 12px;
    height: 42px;
    max-width: 170px;
    padding: 14px 24px;
  }
}

.dashboard-button {
  line-height: 1em;

  &--active {
  }
}
