@import '../../scss/templates';

.page-body.blogger {
  .desktop {
    display: initial;
    @media screen and (max-width: 576px) {
      display: none;
    }
  }
  .mobile {
    display: none;
    @media screen and (max-width: 576px) {
      display: initial;
    }
  }

  .blogger__price {
    
  }

  .blogger-page_card-header {
    display: flex;
    padding: 1rem 2rem 0;

    @media screen and (max-width: 576px) {
      padding: 1rem 1.5rem 0;

      flex-direction: column !important;

      > div.d-flex {
        // justify-content: space-between;
        justify-content: space-between;
        width: 100%;
        border-bottom: 1px solid $color-purple;
        padding-bottom: 0.75rem;
        margin-bottom: 1rem;
      }

      h3 {
        border: none !important;
      }

      .blogger__themes {
        justify-content: center !important;
      }
    }
  }

  .blogger__themes {
    width: 50%;

    @media screen and (max-width: 576px) {
      width: 100%;
    }
  }

  .blogger__similar {
    .similar-card__body {
      padding: 1.5rem 2rem;
      @media screen and (max-width: 576px) {
        padding: 1rem;
      }
    }
    .swiper {
      @media screen and (max-width: 576px) {
        margin: 0 auto;
      }
    }

    .swiper-button-prev,
    .swiper-button-next {
      top: 10%;
    }

    .swiper-button-prev {
      left: 40px;
    }

    .swiper-button-next {
      right: 40px;
    }
  }
}
