@import '../../../scss/templates';

.bordered > * {
  @extend %bordered;
}

.bordered-thick > * {
  @extend %thick-bordered;
  border-width: 2px;
}
