@import '../../../scss/templates';

.plus-button {
  width: 100px;
  height: 100px;
  border: none;
  background: transparent;
  transform: scale(1);
  transition: transform 0.1s ease-in;
  &:hover {
    transform: scale(1.1);
  }
}
