@import '../../../scss/variables';

%message {
  display: flex;

  div {
    min-width: 10px;
    max-width: 200px;
    padding: 20px;
    word-wrap: normal;
    overflow-wrap: break-word;
  }
}

.me {
  @extend %message;
  justify-content: flex-end;
  div {
    border: 1px solid $color-pink;
    background-color: $color-pink--pale;
    padding-left: 10px;

    text-align: right;
  }
}

.companion {
  @extend %message;
  justify-content: flex-start;
  div {
    border: 1px solid $color-purple;
    background-color: $color-purple--pale;
    padding-left: 10px;
    text-align: left;
  }
}
