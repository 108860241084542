@import '../../../scss/templates';

.radio {
  input[type='radio'] {
    visibility: hidden;
    width: 0;
    height: 0;

    margin: 0;
    & + label {
      @extend %bordered;
      @extend %text-14-regular;
      cursor: pointer;
      margin-top: -15px;
      flex-grow: 1;
      flex-shrink: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px 30px;

      * {
        color: black;
      }
    }

    &:checked + label {
      @extend %background-purple;
      color: white !important;

      * {
        color: white !important;
      }
    }
  }
}
