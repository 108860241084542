@import '../../../scss/templates';

.table-header {
  @extend %text-17-bold;
  @extend %text-title;
}

%table-cell {
  text-align: center;
  vertical-align: top;
  position: relative;
  padding: 25px 0;

  & > * {
    display: inline-block;
    height: auto;
  }

  &:first-child {
    padding-left: 0px;
  }

  &:last-child {
    padding-right: 0px;
  }

  @media screen and (max-width: 575px) {
    &:first-child {
      padding-left: 30px;
      padding-right: 10px;
    }
  }
}

.table-container {
  height: 100%;

  //@extend %bordered;

  thead {
    display: table-header-group;

    & > * {
      height: 50px;
      @extend %background-purple--pale;
      text-align: center;

      th {
        @extend %table-cell;
        margin-bottom: 15px;
      }
    }

    tr {
      &:after {
        display: none;
      }
    }
  }

  tr {
    min-height: 35px;
    position: relative;

    &:empty {
      height: 35px;
      background-color: red;

      &:after {
        display: none;
      }
    }

    &:after {
      content: '';
      position: absolute;
      height: 1px;
      left: 0px;
      bottom: 0;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.1);

      @media screen and (max-width: 565px) {
        left: 30px;
        width: calc(100% - 60px);
      }
    }
  }

  td {
    @extend %table-cell;
    background-color: transparent;
    text-align: center;
    vertical-align: middle;
    position: relative;
    padding: 15px 0px;
    width: auto;
    word-break: break-word;

    // padding-left: 70px;
    max-width: 50px;

    * {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media screen and (max-width: 1400px) {
    td {
      max-width: 120px;
    }
  }
}

.table {
  height: 100%;

  * {
    background-color: transparent;
  }
  :global(.th-mw-150) {
    min-width: 150px;
  }
}
