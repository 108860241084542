@import './scss/main';

@import url('https://fonts.googleapis.com/css2?family=Krona+One&display=swap');

@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/Lato/Lato-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/Lato/Lato-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Geometria';
  src:
    url('./assets/fonts/Geometria/Geometria-Bold.ttf') format('truetype'),
    url('./assets/fonts/Geometria/geometria_bold.otf') format('opentype');
  font-weight: 700;
}

:root {
  --tblr-black-rgb: 17, 17, 17 !important;
  --tblr-font-sans-serif: 'Lato', sans-serif !important;
  --main-purple: #515cd1;
  --main-purple-pale: rgba(96, 109, 255, 0.15);
  --main-pink: #ff5fab;
  --tblr-body-bg: white !important;
  --tblr-heading-color: #111111;
  --tblr-body-color: #111111 !important;
  --tblr-font-size-h2: 25px !important;

  @media screen and (max-width: 576px) {
    --tblr-font-size-h2: 20px !important;
  }
}

html {
  color: #111111;
  font-size: 16px;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.h1-title {
  font-family: 'Krona One', sans-serif;
  font-size: 2.8rem;
  line-height: 1em;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(89.76deg, #515cd1 0.21%, #ff5fab 99.79%);
  -webkit-background-clip: text;
}

h2 {
  font-family: 'Geometria', sans-serif;
}

.h2-title {
  font-family: 'Geometria', sans-serif;
  font-size: 1.875rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.purple {
  color: var(--main-purple);
}

.pink {
  color: var(--main-pink);
}

.input-icon-btn:hover {
  transform: scale(1.1);
}

.column {
  display: flex;
  flex-direction: column;
}

.chat-card {
  cursor: pointer;
}

.chat-card:hover {
  background-color: var(--tblr-primary-darken);
}

input[type='date']::-webkit-calendar-picker-indicator {
  cursor: pointer;
  border-radius: 4px;
  margin-right: 2px;
  opacity: 0.8;
  filter: invert(1);
}

input[type='date']::-webkit-calendar-picker-indicator:hover {
  opacity: 1;
}

div.phone-input {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

div.phone-input .form-control {
  width: 100%;
  border-color: #ced4da;
}

div.phone-input-valid .form-control:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--tblr-success-rgb), 0.25);
}

div.phone-input-valid .form-control {
  border-color: var(--tblr-form-valid-border-color);
  padding-right: calc(1.4285714286em + 0.875rem);
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2724%27 height=%2724%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%232fb344%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27%3e%3cpolyline points=%2720 6 9 17 4 12%27%3e%3c/polyline%3e%3c/svg%3e');
  background-repeat: no-repeat;
  background-position: right calc(0.3571428572em + 0.21875rem) center;
  background-size: calc(0.7142857143em + 0.4375rem)
    calc(0.7142857143em + 0.4375rem);
}

div.phone-input-valid .flag-dropdown {
  border-color: var(--tblr-form-valid-border-color);
}

div.phone-input-invalid .form-control:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--tblr-danger-rgb), 0.25);
}

div.phone-input-invalid .form-control {
  border-color: var(--tblr-form-invalid-border-color);
  padding-right: calc(1.4285714286em + 0.875rem);
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2724%27 height=%2724%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%23d63939%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27%3e%3cline x1=%2718%27 y1=%276%27 x2=%276%27 y2=%2718%27%3e%3c/line%3e%3cline x1=%276%27 y1=%276%27 x2=%2718%27 y2=%2718%27%3e%3c/line%3e%3c/svg%3e');
  background-repeat: no-repeat;
  background-position: right calc(0.3571428572em + 0.21875rem) center;
  background-size: calc(0.7142857143em + 0.4375rem)
    calc(0.7142857143em + 0.4375rem);
}

div.phone-input-invalid .flag-dropdown {
  border-color: var(--tblr-form-invalid-border-color);
}

.not-found {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0;
}

.v-line {
  content: ' ';
  width: 1px;
  height: 100%;
  background-color: var(--main-purple);
}

.min-w-fit{
    min-width: fit-content !important;
}

.border-r_gradient {
  border-image: linear-gradient(0deg, #515cd1 0%, #ff5fab 100%) 30;
  border-width: 0;
  border-right-width: 2px;
  border-style: solid;

  @media screen and (max-width: 768px) {
    border: none;
  }
}

.border-t_gradient {
  border-image: linear-gradient(135deg, #515cd1 0%, #ff5fab 100%) 30;
  border-width: 0;
  border-top-width: 1px;
  border-style: solid;
}

.border-t_gradient {
  border-image: linear-gradient(135deg, #515cd1 0%, #ff5fab 100%) 30;
  border-width: 0;
  border-top-width: 1px;
  border-style: solid;
}

.border_purple {
  @extend %bordered;
  &_bold {
    @extend %bordered;
    border-width: 2px;
  }
}

.border-t_purple {
  border-top: 1px solid var(--main-purple);
}

.border-r_purple {
  border-right: 1px solid var(--main-purple);
}

.border-b_purple {
  border-bottom: 1px solid var(--main-purple);
}

.border-l_purple {
  border-left: 1px solid var(--main-purple);
}

.rc-slider-rail {
  background-color: #e0e0e0 !important;
}

.rc-slider-track {
  background-color: var(--main-purple) !important;
}

.rc-slider-handle {
  border-color: var(--main-purple) !important;
}

.rc-slider-handle-dragging {
  box-shadow: 0 0 0 5px var(--main-purple-pale) !important;
}

details summary {
  list-style-type: none;
}

/* Firefox */
details summary::-webkit-details-marker {
  display: none;
}

/* Chrome */
details summary::marker {
  display: none;
}

details summary {
  display: flex;
  align-items: center;
}

details summary .arrow {
  width: 11px;
  height: 11px;
  border-right: 3px solid #8c8c8c;
  border-bottom: 3px solid #8c8c8c;
  transform: rotate(135deg);
  margin-left: auto;
  margin-right: 1em;

  transition: transform 0.2s linear;
}

details[open] summary .arrow {
  transform: rotate(45deg);
}

.bg-light {
  background-color: #ffffff !important;
}

.bg-purple-pale {
  background-color: rgba(96, 109, 255, 0.15) !important;
}

.visible-hidden {
  visibility: hidden;
}

/* */
.container-xl {
  width: 100%;
  max-width: 1400px !important;
  margin: 0 auto;
  padding: 0 20px;
}

.recharts-wrapper .recharts-cartesian-grid-horizontal line:nth-last-child(2),
.recharts-wrapper .recharts-cartesian-grid-horizontal line:last-child,
.recharts-wrapper .recharts-cartesian-grid-vertical line:last-child,
.recharts-wrapper .recharts-cartesian-grid-vertical line:nth-last-child(2) {
  display: none !important;
}

@media screen and (max-width: 1600px) {
  .container-xl {
    max-width: 1280px;
  }
}

@media screen and (max-width: 1200px) {
  .container-xl {
    max-width: 1024px !important;
    padding: 0 10px;
  }
}

@media screen and (max-width: 992px) {
  .container-xl {
    max-width: 720px;
    padding: 0 5px;
  }
}

@media screen and (max-width: 768px) {
  .-xl {
    max-width: 100%;
    padding: 0 5px;
  }

  .h1-title {
    font-size: 3rem;
  }
  .h2-title {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 480px) {
  .h1-title {
    font-size: 2rem;
  }
  .h2-title {
    font-size: 1rem;
  }
}

@media screen and (max-width: 320px) {
  .h1-title {
    font-size: 2rem;
  }
  .h2-title {
    font-size: 1rem;
  }
}
