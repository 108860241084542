@import 'variables';

%bordered {
  border: 1px solid $color-purple !important;
  border-radius: unset !important;
}

%pseudo {
  position: relative;
  &:after {
    content: '';
    position: absolute;
  }
}

%pseudo--full-size {
  &:after {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}

%thick-bordered {
  @extend %bordered;
  border-width: 2px;
}

%background-purple--pale {
  background: $color-purple--pale;
}

%background-purple {
  background: $color-purple;
}

%background-pink {
  background: $color-pink;
}

%background-gradient {
  background: $color-gradient;
}

%disabled {
  opacity: 0.3;
}

%disabled-text {
  color: $color-grey;
}

%text-gradient {
  background: $color-gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

%text-16-bold {
  font-size: 16px;
  font-weight: bold;
}

%text-17-bold {
  font-size: 17px;
  font-weight: bold;
}

%text-17-regular {
  font-size: 17px;
  font-weight: 400;
}

%text-title {
  text-transform: uppercase;
  font-family: 'Geometria', sans-serif;
}

%text-14-regular {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
}

%text-25-bold {
  font-size: 25px;
  font-weight: bold;
  vertical-align: top;
  line-height: 31.45px;
}

%text-25-bold {
  font-size: 25px;
  font-weight: bold;
  line-height: 31px;
}

%modal_content {
  padding: 48px 67px;
}
